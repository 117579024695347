//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-120:_7560,_1494,_9616,_2344,_856,_3652,_6496,_8136;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-120')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-120', "_7560,_1494,_9616,_2344,_856,_3652,_6496,_8136");
        }
      }catch (ex) {
        console.error(ex);
      }